import { Card, CardContent } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { Heading, HyperLink } from "./common/heading";
import { cn } from "@/lib/utils";
import InviteCard from "./common/InviteCard";
import { useCallback, useRef, useState } from "react";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
const PDFInvites = ({ invites, redTheme }) => {
  const properties = {
    duration: 2000,
    transitionDuration: 500,
    infinite: true,
    arrows: false,
  };

  const [index, setIndex] = useState(0);
  const slideRef = useRef(null);

  const handleIndicatorClick = useCallback((clickedIndex) => {
    if (slideRef.current) {
      slideRef.current.goTo(clickedIndex);
      setIndex(clickedIndex);
    }
  }, []);
  if (invites.length === 0) return null;
  return (
    <div>
      <div
        className={cn(
          "pl-4 pr-2 py-0.5 hidden sm:block",
          redTheme ? "bg-lightRed" : ""
        )}
      >
        <div className="flex justify-between items-center pr-2">
          <Heading
            title={"PDF Invites"}
            classes={cn(redTheme ? "text-white" : "")}
          />
          <HyperLink
            link={`/designs/wedding-pdf-invitation`}
            redTheme={redTheme}
          />
        </div>
        <Carousel
          opts={{
            align: "start",
          }}
          className={cn(`w-full max-w-full`, redTheme ? "lg:pb-16" : "")}
        >
          <CarouselContent>
            {invites.map((invite, index) => (
              <CarouselItem
                key={index}
                className="basis-[28%] lg:basis-[15.666667%] hover:cursor-pointer"
                onClick={() =>
                  window.open(
                    `/designs/wedding-pdf-invitation/${invite.slug}`,
                    "_blank"
                  )
                }
              >
                <InviteCard invite={invite} redTheme={redTheme} type="pdf" />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>

      <div className="block sm:hidden">
        <div
          className={cn(
            "lg:mx-4 mb-8 py-0.5 relative",
            redTheme ? "bg-lightRed" : ""
          )}
        >
           <div className="flex justify-between items-center mb-4 mx-4">
            <Heading
              title={"PDF Invites"}
              classes={cn(
                "",
                redTheme ? "text-white mt-0" : "text-lightRed  mt-0"
              )}
            />
            <HyperLink
              link={`/designs/wedding-pdf-invitation`}
              redTheme={redTheme}
            />
          </div>

          <div className="">
            <Slide
              {...properties}
              onChange={(o, newIndex) => setIndex(newIndex)}
              ref={slideRef}
            >
              {invites.slice(0, 5).map((invite, index) => (
                <InviteCard invite={invite} redTheme={redTheme} type="pdf" />
              ))}
            </Slide>
          </div>
          <div className="-mt-2">
            <div className="flex items-center justify-center gap-2">
              {invites.slice(0, 5).map((_, i) => (
                <div
                  key={i}
                  className={cn(
                    `size-2.5 rounded-full cursor-pointer`,
                    index === i ? "bg-lightRed" : "bg-[#EBEBEB]"
                  )}
                  onClick={() => {
                    handleIndicatorClick(i);
                  }}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFInvites;
